/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'antd'
import Icon from '@ant-design/icons'
import CarouselSlider from '../../components/CarouselSlider/CarouselSlider'
import Button from '../../components/Button'
import Map from '../Map/Map'
import { ReactComponent as ArrowRight } from '../../static/icons/arrow-right.svg'

import useSystemLanguage from '../../hooks/useSystemLanguage'

import { sliderTypeClasses } from '../../utils/constants'

import './StaticPage.scss'

const RestaurantDescription = ({ t }) => {
  const { systemLanguage } = useSystemLanguage()

  const UkrainianDescription = () => (
    <p className="description">
      Ми зібрали улюблену кухню з різних куточків світу, яку готуємо з
      перевірених свіжих продуктів.
      <br />
      Щоб остаточно прокинутися і почати день з тої ноги, радимо переглянути
      меню сніданків.
      <br />
      В основному меню є все від закусок до десертів, а для маленьких гостей є
      адаптоване дитяче меню.
      <br />
      Відчути на смак пору року можна завдяки сезонному меню. Не біжіть нікуди
      після, візьміть келих улюбленого вина або крафтового пива з винної або
      барної карти, і побудьте тут і зараз.
    </p>
  )

  const RussianDescription = () => (
    <p className="description">
      Мы собрали любимую кухню из разных уголков мира, которую готовим из
      проверенных свежих продуктов.
      <br />
      Чтобы окончательно проснуться и начать день с той ноги, рекомендуем
      посмотреть меню завтраков
      <br />
      В основном меню все – от закусок до десертов, а для маленьких гостей есть
      адаптированное детское меню.
      <br />
      Почувствовать на вкус время года можно благодаря сезонному меню. Не бегите
      никуда после – возьмите бокал любимого вина или крафтового пива из винной
      или барной карты, и побудьте здесь и сейчас.
    </p>
  )

  const EnglishDescription = () => (
    <p className="description">
      We have collected our favorite cuisine from different parts of the world,
      which we prepare from selective fresh ingredients.
      <br />
      To have a perfect start to the day, we recommend checking out the
      breakfast menu.
      <br />
      The main menu has everything - from appetizers to dessert, and for younger
      guests, there is an adapted children's menu.
      <br />
      Our seasonal menu will bring the taste of the season. You do not need to
      rush anywhere after - grab a glass of your favorite wine or craft beer
      from the bar list and enjoy the moment here and now.
    </p>
  )

  const showAboutUsDescription = () => {
    switch (systemLanguage) {
      case 'ru':
        return <RussianDescription />
      case 'en':
        return <EnglishDescription />
      default:
        return <UkrainianDescription />
    }
  }

  useEffect(() => {
    showAboutUsDescription()
  }, [systemLanguage])

  const menuLinks = {
    ukr: 'Shelest_menu',
    ru: 'Shelest_menu',
    en: 'Shelest_menu_eng',
  }

  return (
    <>
      {showAboutUsDescription()}
      <div className="actions">
        <a
          href="https://expz.menu/47ce63ea-9e49-4eb1-877f-9083b49a137e"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button classNames="btn-mid-pad small-btn btn-red">
            {t('Restaurant.Actions.Menu')}
          </Button>
        </a>
        {/* <NavLink to="/booking/restaurant">
          <Button classNames="btn-mid-pad small-btn btn-red">
            {t('Restaurant.Actions.BookATable')}
          </Button>
        </NavLink> */}
      </div>
    </>
  )
}

const PoolDescription = ({ t, i18n }) => {
  const { language } = i18n

  const UkrainianDescription = () => (
    <p className="description">
      Подивившись на фото, одразу хочеться сюди, чи не так? Басейн посеред
      природи, сонце та спокій — це відпочинок у стилі SHELLEST. Втім, як
      релаксувати, вибираєте тільки ви: читати, плавати, засмагати, смакувати
      улюблені коктейлі або ж все й одразу. Працюють 2 великих та 1 басейн для
      малечі.
      <br />
      <br />
      Забронювали номер, але хочеться плавати й засмагати ще до заселення? У
      день заїзду необхідно сплатити 50% від вартості відвідування. Якщо
      хочеться продовжити відпочинок і лишитися після виселення — повну
      вартість.
      <br />
      <br />
      Приємного відпочинку в SHELEST.
    </p>
  )

  const RussianDescription = () => (
    <p className="description">
      Программа антистресс у нашего бассейна 50-ти метров – это загорать,
      плескаться в воде и радоваться солнечной погоде. Пить охлажденные напитки,
      попробовать барное меню и бездельничать среди природы.
      <br />
      А можно проникнуть в чтение любимой книги или просто наблюдать за бликами
      солнца на воде. Чувствуйте, как замедляются мысли и расслабляется тело.
      <br />
      <br />
      Рядом с основным бассейном есть неглубокий бассейн для детей, который
      подходит даже для самых маленьких.
    </p>
  )

  const EnglishDescription = () => (
    <p className="description">
      With the anti-stress program at our 50-meter pool you will be sunbathing,
      splashing in the water and enjoying the sunny weather. As well as drinking
      cool drinks, tasting the bar menu and relaxing in nature.
      <br />
      Or you can immerse yourself in reading your favorite book or just watch
      the sun's reflections on water. Feel how your thoughts slow down and the
      body relaxes.
      <br />
      <br />
      Next to the main pool there is a shallow children's pool, which is
      suitable even for the little ones.
    </p>
  )

  const showAboutUsDescription = () => {
    switch (language) {
      case 'ru':
        return <RussianDescription />
      case 'en':
        return <EnglishDescription />
      default:
        return <UkrainianDescription />
    }
  }

  useEffect(() => {
    showAboutUsDescription()
  }, [language])

  return (
    <>
      {showAboutUsDescription()}
      <div className="actions">
        <a
          href="https://expz.menu/7ad2e6aa-0c17-4a8b-8e54-b106883b9ba4"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button classNames="btn-mid-pad small-btn btn-blue">
            {t('Pool.Actions.Menu')}
          </Button>
        </a>
        <a
          href=" https://tickets.shelest.ua/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button classNames="btn-mid-pad small-btn btn-green">
            {t('Pool.Actions.WantToSwim')}
          </Button>
        </a>
        <a
          href=" https://shelest.ua/rules-in-water.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button classNames="btn-mid-pad small-btn btn-blue">
            {t('Pool.Actions.InWaterRules')}
          </Button>
        </a>
      </div>
    </>
  )
}

const CovidDescription = ({ t, i18n }) => {
  const { language } = i18n

  const UkrainianDescription = () => (
    <p className="description">
      Робіть нічого безпечно.
      <br /> <br />
      Наша команда вакцинована. Усі працівники дотримуються маскового режиму.
      Втечіть з міста, щоб насолодитись природою та відпочити.
      <br /> <br />
      Для відвідування shelest.hotel необхідно надати негативний ПЛР-тест або
      сертифікат вакцинації. Усі гості можуть пройти експрес-тестування на
      COVID-19 у нас.
      <br /> <br />
      Приємного відпочинку!
    </p>
  )

  const EnglishDescription = () => (
    <p className="description">
      Your safety and health is a priority of SHELEST. We follow all the
      recommendations of the Ministry of Health to prevent the spread of the
      virus. Quarantine measures may slightly change the format of our work, but
      we do our best not to limit your vacation. Thank you for your trust.
      <br />
      The latest news on the distribution of Covid - 19 in Ukraine can be viewed
      at the link.
    </p>
  )

  const RussianDescription = () => (
    <p className="description">
      Делайте ничего безопасно.
      <br /> <br />
      Наша команда вакцинирована. Все работники соблюдают масочный режим.
      Убегите из города, чтобы насладиться природой и отдохнуть.
      <br /> <br />
      Для посещения shelest.hotel необходимо предоставить отрицательный ПЦР-тест
      или сертификат вакцинации. Все гости могут пройти экспресс-тестирование на
      COVID-19 у нас.
      <br /> <br />
      Приятного отдыха!
    </p>
  )

  const showDescription = () => {
    switch (language) {
      case 'ru':
        return <RussianDescription />
      case 'en':
        return <EnglishDescription />
      default:
        return <UkrainianDescription />
    }
  }

  useEffect(() => {
    showDescription()
  }, [language])

  return (
    <>
      {showDescription()}
      <div className="actions">
        <a
          href="https://covid19.gov.ua"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            classNames="btn-mid-pad small-btn btn-red"
            buttonId="covid_news_button"
          >
            {t('Covid.Actions.CovidNewsLink')}
          </Button>
        </a>
      </div>
    </>
  )
}
//<!--Ресторан щоденно з 11:00 до 22:00-->
const ContactsDescription = ({ t, i18n }) => {
  const { language } = i18n

  const UkrainianDescription = () => (
    <>
      <p className="description">
      
Графік роботи з листопада:<br/>
Пт - Нд 10:00-22:00<br/>
Пн - до 16:00
      </p>
      
      <p className="description">Обслуговування номерів - 24/7</p>
      <p className="description">
        <a
          target="_blank"
          href="https://www.google.com.ua/maps/place/SHELEST/@50.6566222,30.4941127,11.75z/data=!4m9!3m8!1s0x472b2bdf65c04d29:0xfc619ad8fc55e974!5m2!4m1!1i2!8m2!3d50.6652681!4d30.5243307!16s%2Fg%2F11fn1xl0dz?hl=ru&authuser=0"
          rel="noreferrer"
        >
          {t('Contacts.Location')}
        </a>
      </p>
      
      <div className="row">
        <p className="description">
          Телефон: <a href="tel:+380671100067" className="ringostat">+38 (067) 11 000 67</a>{' '}<br/>
         Під час дзвінка натисніть потрібну кнопку для зв’язку з відділом:<br/>
          1. Відділ бронювання / Рецепція<br/>
          2. Ресторан<br/>
          3. Івент-відділ<br/>
          4. Дитяча кімната<br/>
          5. Придбання інвестицій<br/>
        </p>
      </div>
{/*
      <div className="row">
        <p className="description">
          <a href="tel:+380671100067" className="ringostat">+38 (067) 11 000 67</a>{' '}
          {t('Contacts.BookingRoomTitle')}
        </p>
        <p className="description">
          <a href="tel:+380676727885">+38 (067) 672 78 85</a>{' '}
          {t('Contacts.ManagerTitle')}
        </p>
      </div>
      <div className="row">
        <p className="description">
          <a href="tel:+380987620313">+38 (098) 762 03 13</a>{' '}
          {t('Contacts.ShelestRestaurant')}
        </p>
      </div>
      */}
    </>
  )
//<!--Restaurant is open from 11.00 am to 22:00 pm-->
  const EnglishDescription = () => (
    <>
      <p className="description">
        
        Open hours from November:<br/>
Fri - Sun 10:00 - 22:00<br/>
Mon till 16:00
      </p>
      
      <p className="description">Room Service - 24/7</p>
      <p className="description">
        <a
          target="_blank"
          href="https://www.google.com.ua/maps/place/SHELEST/@50.6566222,30.4941127,11.75z/data=!4m9!3m8!1s0x472b2bdf65c04d29:0xfc619ad8fc55e974!5m2!4m1!1i2!8m2!3d50.6652681!4d30.5243307!16s%2Fg%2F11fn1xl0dz?hl=ru&authuser=0"
          rel="noreferrer"
        >
          {t('Contacts.Location')}
        </a>
      </p>
      <div className="row">
        <p className="description">
          Phone: <a href="tel:+380671100067" className="ringostat">+38 (067) 11 000 67</a>{' '}<br/>
         During the call, press the corresponding button to connect with the department:<br/>
1. Booking Department / Reception<br/>
2. Restaurant<br/>
3. Event Department<br/>
4. Kids&apos; Room<br/>
5. Investment Purchase<br/>

        </p>
      </div>
      {/*
      <div className="row">
        <p className="description">
          <a href="tel: +38 (067) 11 000 67">+38 (067) 11 000 67</a>{' '}
          {t('Contacts.BookingRoomTitle')}
        </p>
        <p className="description">
          <a href="tel: +38 (067) 672 78 85">+38 (067) 672 78 85</a>{' '}
          {t('Contacts.ManagerTitle')}
        </p>
      </div>
      <div className="row">
        <p className="description">
          <a href="tel: +38 (098) 762 03 13">+38 (098) 762 03 13</a>{' '}
          {t('Contacts.ShelestRestaurant')}
        </p>
      </div>
      */}
    </>
  )

  const showDescription = () => {
    switch (language) {
      case 'ru':
        return <UkrainianDescription />
      case 'en':
        return <EnglishDescription />
      default:
        return <UkrainianDescription />
    }
  }

  useEffect(() => {
    showDescription()
  }, [language])

  return (
    <>
      {showDescription()}
      <div className="row">
        <p className="description">
          Email
          <br />
          <a href="mailto:ask@shelest.ua">ask@shelest.ua</a>
        </p>
      </div>
      <div className="rules-block">
        <div className="rule">
          <h3>{t('Booking.Cart.Rules.Guarantees.Title')}</h3>
          <p>{t('Booking.Cart.Rules.Guarantees.Description')}</p>
          <div className="link">
            <a
              href="https://shelest.ua/shelest-pravyla-perebuvannia.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Booking.Cart.Rules.Guarantees.ButtonTitle')}
              <Icon component={ArrowRight} />
            </a>
          </div>
        </div>
        <div className="rule">
          <h3>{t('Booking.Cart.Rules.Cancelling.Title')}</h3>
          <p>{t('Booking.Cart.Rules.Cancelling.Description')}</p>
          <div className="link">
            <a
              href="https://shelest.ua/shelest-public-offer.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Booking.Cart.Rules.Cancelling.ButtonTitle')}
              <Icon component={ArrowRight} />
            </a>
          </div>
        </div>
      </div>

      <div className="rules-block">
        <div className="rule">
          <h3>{t('Booking.Cart.Rules.ChildSupervision.Title')}</h3>
          <p>{t('Booking.Cart.Rules.ChildSupervision.Description')}</p>
          <div className="link">
            <a
              href="https://shelest.ua/public_agreement_on_the_provision_of_temporary_services_with_the_organization_of_child_supervision.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Booking.Cart.Rules.ChildSupervision.ButtonTitle')}
              <Icon component={ArrowRight} />
            </a>
          </div>
        </div>

      </div>
      <div className="map-wrapper">
        <Map
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBw1AP70J8_9asSovri9aetdYG_i5YWu9M&v=3.exp&libraries=geometry,drawing,places&language=en"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div className="map-inner" />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    </>
  )
}

const StaticPage = ({
  page,
  titleImageUkr,
  titleImageRu,
  titleImageEn,
  sliderImages,
}) => {
  const { t, i18n } = useTranslation()

  const { staticPage } = sliderTypeClasses
  const { language } = i18n

  const getImage = () => {
    switch (language) {
      case 'ukr':
        return titleImageUkr
      case 'ru':
        return titleImageRu
      case 'en':
        return titleImageEn
      default:
        return
    }
  }

  const getDescription = () => {
    switch (page) {
      case 'restaurant':
        return <RestaurantDescription t={t} i18n={i18n} />
      case 'pool':
        return <PoolDescription t={t} i18n={i18n} />
      case 'сovid':
        return <CovidDescription t={t} i18n={i18n} />
      case 'contacts':
        return <ContactsDescription t={t} i18n={i18n} />
      default:
        return
    }
  }

  useEffect(() => {
    getImage()
  }, [language])

  return (
    <div className="upper-wrapper">
      <div className="static-page-wrapper">
        <div className={`static-page ${page}`}>
          <div className="static-page-image-wrapper">
            <img className="image" src={getImage()} alt={page} />
          </div>
          <div className="static-page-description-wrapper">
            {getDescription()}
          </div>
        </div>
      </div>
      <CarouselSlider carouselItems={sliderImages} className={staticPage} />
    </div>
  )
}

StaticPage.propTypes = {
  page: PropTypes.string.isRequired,
  titleImageUkr: PropTypes.string.isRequired,
  titleImageRu: PropTypes.string.isRequired,
  titleImageEn: PropTypes.string.isRequired,
  sliderImages: PropTypes.array.isRequired,
}

StaticPage.defaultProps = {}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(StaticPage)
