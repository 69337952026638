import React from 'react'
// import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import EventLink from '../../components/EventLink'
import HeroImage from '../../components/HeroImage'
import Button from '../../components/Button'

import EventsBG from '../../static/images/events/events-bg.jpg'

import events from './constants'

import './Events.scss'

const UkrDescription = (
  <>
    <p style={{ textAlign: 'center' }}>
      Довкола — природа, поруч — найближчі, в душі — тепло.
    </p>
    <br />
    <p style={{ textAlign: 'center' }}>
      Коли закохані серця стають родиною і коли малеча дорослішає на рік. Коли
      хочеться веселитися з колегами і коли потрібно провести бізнес-зустріч.
      Яка б подія не була, у нас завжди є місце для ваших моментів.
    </p>
    <br />
    <div className="wedding-wrapper">
      <p>
        Ваше свято в SHELEST запам’ятається естетикою декору, смачною кухнею,
        турботливим сервісом і особливою атмосферою.
      </p>
      <br />
      <p>Приділіть увагу тому, що дійсно важливо. Про все інше подбаємо ми.</p>
      <p style={{ textAlign: 'center' }}>
        Відділ банкетного сервісу <a href="tel:+380671100067" className="ringostat">+38 (067) 11 000 67</a>.<br/>
        Під час дзвінка в голосовому меню обирайте кнопку 3, щоб зв’язатися з івент-відділом.
      </p>
      <br />
    </div>
  </>
)

const RuDescription = (
  <>
    <p style={{ textAlign: 'center' }}>
      Вокруг – природа, рядом – близкие люди, в душе – тепло.
    </p>
    <br />
    <p style={{ textAlign: 'center' }}>
      Когда влюбленные сердца становятся семьей и когда дети взрослеют на год.
      Когда хочется веселиться с коллегами и когда нужно провести
      бизнес-встречу. Какое бы событие ни было, у нас всегда есть место для
      ваших моментов.
    </p>
    <br />
    <div className="wedding-wrapper">
      <p>
        Ваш праздник в SHELEST запомнится эстетикой декора, вкусной кухней,
        заботливым сервисом и особенной атмосферой.
      </p>
      <br />
      <p>
        Уделите внимание тому, что действительно важно. Про все остальное
        позаботимся мы.
      </p>
      <br />
      <p style={{ textAlign: 'center' }}>
        Отдел банкетного сервиса <a href="tel:+380676727885">+38(067) 672 78 85</a>.
      </p>
      <br />
      <div style={{ marginTop: 20 }}>
        <NavLink to="events/weddings">
          <Button classNames="btn-green h-40" buttonId="events_wedding_button">
            Свадьба у нас
          </Button>
        </NavLink>
      </div>
    </div>
  </>
)

const EngDescription = (
  <>
    <p style={{ textAlign: 'center' }}>
      Nature is all around, the closest ones are nearby, and warmth is in the
      soul.
    </p>
    <br />
    <p style={{ textAlign: 'center' }}>
      When loving hearts become a family and when a child gets a year older.
      When you want to have fun with colleagues and when you need to hold a
      business meeting. Whatever the event, we always have a place for your
      special moments.
    </p>
    <br />
    <div className="wedding-wrapper">
      <p>
        Your holiday at SHELEST will be remembered by the aesthetics of the
        decor, delicious cuisine, caring service, and a special atmosphere.
      </p>
      <br />
      <p>Pay attention to what is important. We will take care of the rest.</p>
      <p style={{ textAlign: 'center' }}>
        Banquet service department <a href="tel:+380671100067" className="ringostat">+38 (067) 11 000 67</a>.<br/>
        During the call, select button 3 in the voice menu to connect with the event department.
      </p>
      <br />
      <p>
        A wedding at SHELEST means a stylish location, magic in the air, and
        memories to last a lifetime. To talk about feelings, hug and even cry
        with happiness. To be here and now, and then look over the photos and
        return to this special day.
      </p>
      {/* <div style={{ marginTop: 20 }}>
        <NavLink to="events/weddings">
          <Button classNames="btn-green h-40" buttonId="events_wedding_button">
            Wedding
          </Button>
        </NavLink>
      </div> */}
    </div>
  </>
)

const Events = () => {
  const { t, i18n } = useTranslation()
  const firstEvent = events[0]

  const descriptions = {
    ukr: UkrDescription,
    ru: RuDescription,
    en: EngDescription,
  }

  return (
    <div className="events-wrapper">
      <HeroImage
        backgroundImage={EventsBG}
        heroText={t('Home.Navigation.Events')}
      />
      <div className="events-content-wrapper">
        <div className="events-text">
          {descriptions[i18n.language]}
          <div className="event-link-first">
            <EventLink key={firstEvent.url} event={firstEvent} />
          </div>
          <div className="events-links">
            {events.map(event => {
              const { toRender = true } = event

              if (!toRender) {
                return ''
              }

              return <EventLink key={event.url} event={event} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

Events.propTypes = {}

Events.defaultProps = {}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Events)
